header {
  color: whitesmoke;
  /* overflow: hidden; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: transparent;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 10px 0px;
  transition: 0.4s ease-in-out;
}

header.active {
  padding: 7px 0px;
  background: #10191b;
}

header .logo {
  margin-left: 50px;
  height: auto;
  width: auto;
  max-height: 50px;
  max-width: 100px;
}

header a:hover {
  opacity: 0.7;
  cursor:pointer;
}

header ul {
  list-style: none;
  margin-right: 50px;
  position: relative;
}

header ul ul{
	 position: absolute;
	 top: 200%;
	 /* left: -1rem; */
	 background-color: #10191b;
	 border-radius: 0.3rem;
	 /* width: 12rem; */
	 /* padding: 1rem; */
	 display: flex;
	 flex-direction: column;
	 /* gap: 0.5rem; */
	 opacity: 0;
	 visibility: hidden;
	 transition: opacity 200ms ease-in-out;
}

header ul li:hover > ul{
  	opacity: 1;
	 visibility: visible;
}


header ul ul li{
  display: block;
  list-style: none;
	 padding: 0.5rem 1rem;
	 border-radius: 0.2rem;
	 transition: background-color 200ms ease-in-out;
}


header ul li:hover > ul{
  display: block;
}

header ul li {
  display: inline-block;
}

header ul ul li a {
  padding: 10px 30px;
}

header > ul > li{
  position: relative;
}

header ul li a {
  text-decoration: none;
  color: whitesmoke;
  text-decoration: uppercase;
  opacity: 1;
  padding: 20px 30px;
  font-size: 15px;
  text-transform: uppercase;
}

header ul li a:hover {
  color: #6db6ab;
}

header .menu,
header .close {
  cursor: pointer;
  text-transform: uppercase;
  border: 1px solid whitesmoke;
  padding: 10px 20px;
  margin-right: 20px;
  display: none;
}

header .close {
  position: absolute;
  top: 10px;
  right: 0;
  border-radius: 10px;
}

.menu-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  cursor: pointer;
  color: #64aaa1;
  align-items: center;
  display: none;
}

@media only screen and (max-width: 900px) {

  .menu-icon{
    display: block;
  }

  .fa-times{
    font-size: 2rem;
  }
  .navbar-items-container{
    position: relative;
  }
  .nav-menu{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 68px;  
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active{
    background: #10191b;
    left:0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }


  header ul{
    height: 200px;

  }

header ul ul li{
  display: block;
  list-style: none;
	 padding: 0;
	 border-radius: 0.2rem;
	 transition: background-color 200ms ease-in-out;
}

header ul li a:hover{
  background-color: #64aaa1;
  color: white;
  opacity: 1;
}

  header ul li a{
        display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
       font-size: 20px;
       font-weight:500;

  }

  header ul ul{
    position:static;
	 background-color: #10191b;
	 border-radius: 0.3rem;
	 flex-direction: column;
	 opacity: 100%;
	 visibility:visible;
  transition: all 0.5s ease-in-out;
   height: 0;
   overflow: hidden;
   width: 100%;
  }

  header ul ul a{
    width: 100%;
  }

  header ul li:hover > ul{
    height: 128px;
  }
}

@media screen and (max-width: 500px){
  header .logo {
    margin-left: 20px;
  }
}
