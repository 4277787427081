.demo-content {
  width: 100%;
  height: 200vh;
  background-color: #fff;
}

.cover {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner{
    width: 100%;
    height: 100vh;
    /* background-image: url("/src/assets/cover.jpg"); */
    background-size: cover;
    background-position:50% 35%;
    z-index: 3;
    position: relative;
}

.cover-text {
  /* width: 100%; */
  text-align: center;
  color: white;
  opacity: 1;
  padding: 40vh 0;
}

.cover-text h1 {
  font-size: 50px;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana,
    sans-serif;
}

.cover-text p {
  font-size: 19px;
}


.home-about{
  /* width: 70%; */
  display: flex;
  justify-content: center;
  /* height: 70vh; */
}
.about-content {
  /* margin-bottom: 500px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  margin: 60px auto;
  padding: 0px 150px;
  max-width: 1200px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: 700px;
  margin: 250px 20px;
  line-height: 35px;
}

.about-content h1 {
  font-size: 40px;
  margin-bottom: 40px;
  color: #64aaa1;
  font-weight: 400;
}

.about-content p {
  color: white;
  font-size: 18px;

  font-weight: 300;
}

.social-medias {
  display: flex;
  justify-content: space-around;
  margin: 0px 25%;
  /* margin: 0 auto; */
  margin-bottom: 30px;
  position: relative;
  bottom: 0px;
}

.social-container {
  position: absolute;
  display: inline-block;
  bottom: 0;
  left: 0;
  /* transform: translateX(50%); */
  width: 100%;
}

.social-medias a {
  color: white;
  font-size: 40px;
  transition: all 0.3s ease-out;
}

.social-medias a:hover {
  transform: translate(0, -5px);
}

.instagram {
  padding: 0px 5.375px;
  border-radius: 10px;
}

.instagram:hover {
  /* color: rgb(85, 82, 82); */
  background: radial-gradient(
      circle farthest-corner at 35% 90%,
      #fec564,
      transparent 50%
    ),
    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 20% -50%,
      #5258cf,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 60% -20%,
      #893dc2,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
    linear-gradient(
      #6559ca,
      #bc318f 30%,
      #e33f5f 50%,
      #f77638 70%,
      #fec66d 100%
    );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.facebook {
  padding: 0px 3.5px;
}

.facebook:hover {
  color: #3b5998;
}

.facebook {
  position: relative;
}

.facebook:hover::before {
  position: absolute;
  content: "";

  padding: 18px;
  border-radius: 50px;
  margin-left: 2px;
  margin-top: 7px;
  background: white;
  z-index: -1;
  text-align: center;
}

.youtube:hover {
  color: #ff0000;
}

.youtube {
  position: relative;
}
.youtube:hover::before {
  position: absolute;
  content: "";

  padding: 10px;
  right: 25%;
  bottom: 30%;
  border-radius: 10px;
  background: white;
  z-index: -1;
  text-align: center;
}

.currentteamcontainer{
  text-align: center;
  color: #64aaa1;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  /* margin: 0 50px; */
  /* background-color: rgba(20, 20, 20, 0.534); */
}

hr{
  border: 0;
  border-top: #656b6c 1px solid;
  margin: 0 5%;
}

.title{
  font-size: 60px;
  font-weight: 400;
  padding: 10px;
  text-transform: uppercase;
  background-color: rgba(20, 20, 20, 0.534);
    /* margin-bottom: 100px; */
}

.home-content{
  display: flex;
  flex-direction: column;
}

@media (max-width: 700px) {
  .title{
    font-size: 40px;
  }
}