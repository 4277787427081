/* Animated Circles CSS */

.circles {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
    position: fixed;
}

.circles li {
	position: absolute;
	display: block;
	list-style: none;
	width: 20px;
	height: 20px;
	background: rgba(96, 199, 185, 0.123);
	animation: animate 25s linear infinite;
	bottom: calc(-150px - 10vh);
}

.circles li:nth-child(1) {
	left: 25%;
	width: 80px;
	height: 80px;
	animation-delay: 0s;
	animation-duration: 20s;
    clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
}

.circles li:nth-child(2) {
	left: 10%;
	width: 30px;
	height: 30px;
    clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
	animation-delay: 0s;
	animation-duration: 25s;
}

.circles li:nth-child(3) {
	left: 70%;
	width: 20px;
	height: 20px;
	animation-delay: 0s;
	animation-duration: 20s;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.circles li:nth-child(4) {
	left: 40%;
	width: 60px;
	height: 60px;
	animation-delay: 0s;
	animation-duration: 15s;
    /* border-radius: 50%; */
    clip-path: polygon(13% 13%, 13% 87%, 25% 88%, 25% 25%, 75% 25%, 75% 75%, 25% 75%, 25% 87%, 87% 87%, 86% 13%);
}

.circles li:nth-child(5) {
	left: 65%;
	width: 25px;
	height: 25px;
	animation-delay: 0s;
	animation-duration: 20s;
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
}

.circles li:nth-child(6) {
	left: 75%;
	width: 90px;
	height: 90px;
	animation-delay: 0s;
	animation-duration: 25s;
    clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
}

.circles li:nth-child(7) {
	left: 35%;
	width: 50px;
	height: 50px;
	animation-delay: 0s;
	animation-duration: 30s;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.circles li:nth-child(8) {
	left: 50%;
	width: 25px;
	height: 25px;
	animation-delay: 0s;
	animation-duration: 35s;
}

.circles li:nth-child(9) {
	left: 20%;
	width: 25px;
	height: 25px;
	animation-delay: 0s;
	animation-duration: 40s;
}

.circles li:nth-child(10) {
	left: 85%;
	width: 50px;
	height: 50px;
	animation-delay: 0s;
	animation-duration: 45s;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

@keyframes animate {
	0% {
		transform: translateY(0) rotate(0deg);
		opacity: 0;
	}
	50% {
		transform: translateY(-60vh) rotate(360deg);
		opacity: 1;
	}
	100% {
		transform: translateY(-120vh) rotate(720deg);
		opacity: 0;
	}
}