.cap-border {
  border-radius: 50%;
  padding: 4px;
  border: 1px solid rgb(233, 200, 54);
  margin: 10px;
}

.no-border-bubble-container {
  margin: 10px;
}

.bubble-container {
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  background: rgb(50, 88, 119);
  /* margin: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.bubble-container img{
  display: flex;
  justify-content: space-evenly;
  text-align: center;
}

.initials {
  font-size: 35px;
}

.bubble-image {
  flex-shrink: 1;
  min-width: 100%;
  min-height: 100%;
  font-size: 30px;
}

.hover-name {
  position: absolute;
  top: 50;
  right: 50;
  line-height: 1.5em;
  width: 50px;
  height: 3em;
  font-size: 12px;
  display: flex;
  justify-content: center;
  transform: translateY(7px);
  transition: transform 0.25s;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(19, 19, 19, 0.521);
  opacity: 0;
  cursor: pointer;
  color: white;
}

.image-overlay:hover {
  opacity: 1;
}

.image-overlay:hover > .hover-name {
  transform: translateY(0);
}

.image-overlay::after {
  content: "";
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid #64aaa1;
  transform: scale(1.1);
  transition: all 0.2s;
}

.image-overlay:hover > .bubble-container {
  transform: translateY(20px);
}

.image-overlay:hover::after {
  transform: scale(1);
}


@media (max-width: 700px) {
  .bubble-container{
    width: 60px;
    height:60px;
    font-size: 10px;
  }

  .hover-name{
    font-size: 9px;
  }

  .image-overlay::after{
    width: 60px;
    height: 60px;
  }
}