.timeline-section {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  color: white;
  flex-direction: column;
  padding-bottom: 400px;
}

.timeline-section h1 {
  font-size: 40px;
  font-weight: 200;
  margin-bottom: 100px;
  /* text-decoration: underline; */
  position: relative;
  /* color: #64aaa1; */
  color:#ffffff;
  position: relative;
}

.timeline-section h1::before{
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: -5px;
  background-color:#64AAA1;
  z-index: -1;
}

/* .timeline-section h1::before {
  content: "";
  width: 300px;
  height: 2px;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 50%;
  background: #64AAA1;
  bottom: -6px;
  width: 100%;
} */

.timeline{
    width: 80%;
  height: auto;
  max-width: 800px;
  margin: 0 auto;  
  position: relative;
}

.timeline ul {
  list-style: none;
}

.timeline ul li {
  padding: 0 20px;
  /* background-color: #221e1ec0; */
  color: white;
  border-radius: 10px;
  margin-bottom: 60px;
}

.timeline ul li:last-child {
  margin-bottom: 0;
}

.timeline-content img{
  width: 100%;
  height: auto;
  border-radius: 10px;
  display: block;
}

.timeline-content p{
  padding: 5px 20px;
  font-size: 16px;
}

.year-bg{
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;
  font-size: 80px;
  top: 30%;
  color:#2f977695;
  font-weight: 600;
  user-select: none;
}

.card-border{
  position:relative;
}

.card-border a{
  position: relative;
}


.hvr-float{
    display:inline-block;
    vertical-align:middle;
    -webkit-transform:perspective(1px) translateZ(0);
    transform:perspective(1px) translateZ(0);
    box-shadow:0 0 1px rgba(0,0,0,0);
    -webkit-transition-duration:.3s;
    transition-duration:.3s;
    -webkit-transition-property:transform;
    transition-property:transform;
    -webkit-transition-timing-function:ease-out;
    transition-timing-function:ease-out
}
.hvr-float:active,.hvr-float:focus,.hvr-float:hover{
    -webkit-transform:translateY(-8px);
    transform:translateY(-8px)
}

.card-border{
  position: relative;
}


.card-border .info{
  position: relative;
  z-index: 3;
}

@media only screen and (min-width: 768px) {

  .timeline::before{
    content: '';
    position: absolute;
    height: 110%;
    width: 2px;
    background-color: #fff;
    left: 50%;
    top: -5%;
  }

  .timeline ul li{
    width: 50%;
    margin-bottom: 50px;
    position: relative;
  }

  .timeline ul li::after{
    content:'';
    position: absolute;
    height:15px;
    width: 15px;
    background-color: #aab3b1;
    border-radius: 50%;
    top: 0;
  }

  .timeline ul li:nth-child(odd){
    float: left;
    clear: right;
  }
  .timeline ul li:nth-child(even){
    float: right;
    clear: left;
  }

  .timeline ul li:nth-child(odd)::after{
    right: -1px;
    transform: translate(50%, -50%);
  }
  .timeline ul li:nth-child(even)::after{
    left: 1px;
    transform: translate(-50%, 50%);
  }

  .timeline ul li:hover:after{
    background-color: rgb(0, 255, 179);
  }

  .timeline ul li:hover > .timeline-content p{
    color: rgb(0, 255, 179);
  }

}