section {
  position: relative;
}

.App{
  position: relative;
}
.App::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: url("/src/assets/raw-logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 400px;
  opacity: 0.02;
  animation: fadeIn linear 1.5s;
  z-index: 0;
  -webkit-animation: fadeIn linear 1.5s;
  -moz-animation: fadeIn linear 1.5s;
  -o-animation: fadeIn linear 1.5s;
  -ms-animation: fadeIn linear 1.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.02;
  }
}



.App .section::before {  
      content: "";
      background: url("/src/assets/team.jpg");
      background-position:50% 35%;
      position: absolute;
      background-size: cover;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.02;
}