.profile-wrapper {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.profile-name-title {
  color: white;
  text-transform: capitalize;
  font-size: 35px;
  margin-bottom: 50px;
  font-weight: 200;
  color: #ffd700;
}

.profile-image {
  height: 400px;
  border-radius: 20px;
}

.profile-container {
  display: flex;
  width: 800px;
  justify-content: space-around;
}

.profile-right-container {
  margin-left: 50px;
}

.profile-right-container h2 {
  text-align: center;
  font-weight: 300;
  color: #64aaa1;
  margin-bottom: 10px;
}

.about-text {
  font-weight: 300;
  line-height: 1.3em;
}

/* Left Side details */

.socials-container {
  display: flex;
  flex-direction: column;
  font-size: 20px;
}

.info-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-left: 20px;
}
.socials-container i {
  margin-bottom: 10px;
}

.details-container p {
  font-size: 16px;
  color: #64aaa1;
}

.details-container span {
  color: white;
}

.details-container {
  margin-bottom: 20px;
}
