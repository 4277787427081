.performances-wrapper h1 {
  font-size: 30px;
  color: #64aaa1;
  font-weight: 300;
  margin-bottom: 10px;
  text-align: center;
}

.performances-wrapper {
  /* width: 80%; */
  /* margin-bottom: 100px; */
  /* width: 70%; */
    /* padding: 100px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.videos-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* max-width: 70vw; */
}

.video-wrapper {
  margin: 20px;
  /* width: 200px; */
  border: #b8edd979 1px solid;
  border-radius: 5px;
  padding: 10px;
  overflow: hidden;
}

@media (max-width: 700px){
  .video-wrapper{
    width: 150px;
    height: 90px;
    padding: 5px;
  }
}
