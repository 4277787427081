.linktree-container{
    position: fixed;
    top: 150px;
    right: 100px;
    /* left: 50px; */
    color: rgb(139, 139, 139);
        
    text-align: center;
     /* border: 0.5px solid white; */
}

.linktree-container ul{
    list-style: none;
}

.linktree-container ul li{
    background-color: #455e65;
    margin: 10px;
    border-radius: 5px;
    padding: 10px 50px;
    cursor: pointer;
    transition: all ease-in 0.07s;
}

.linktree-container ul li:hover{
    transform: translateY(-2px);
}

.linktree-container a{
    color: white;
    text-decoration: none;
    padding: 5px;
    font-size: 12px;
    cursor: pointer;
}

.linktree-button {
    position: absolute;
    background-color: #455e65;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    top: -10px;
    right: -10px;
    cursor: pointer;
    z-index: 2;
    border: solid 1px #64aaa1;
    color: #64aaa1;
    font-size: 16px;
}
.linktree-button i{
    background-color: #455e65;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.08s ease-in;
}

.linktree-box{
    background-color: #162225;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    padding: 20px;
    transform: scale(0);
    transform-origin: top right;
    transition: .2s all cubic-bezier(.4,0,.2,1);
}

.linktree-box h1{
    color: #64aaa1;
    margin-bottom: 20px;
}

.linktree-box:hover{
    cursor: move;
}

.linktree-button:hover{
    /* box-shadow: 0px 0px 7px 1px rgba(79,171,204,1); */
    transform: scale(1.1);
}

.fa-link{
    font-size: 13px;
}

.linktree-box.active{
    transform: scale(1);
}

@media (max-width: 900px) {
    .linktree-container{
        top: 100px;
        right: 30px;
        width: 80vw;
    }
}