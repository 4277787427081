.bubbles-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
}

.bubble-section-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.bubble-section-container h1 {
  font-size: 30px;
  color: #64aaa1;
  font-weight: 300;
  margin-bottom: 10px;
}
